/* Projects.css */

#projects {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0;
    max-width: 1200px;
}

#projects h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

#projects .project {
    margin-bottom: 30px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    transition: transform 0.3s ease;
}

#projects .project:hover {
    transform: translateY(-5px);
}

#projects .project-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
}

#projects .project-description {
    font-size: 16px;
    color: #666;
    margin-bottom: 10px;
    text-align: center;
}

#projects .project-link {
    display: block;
    text-align: center;
    color: #007BFF;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

#projects .project-link:hover {
    color: #0056b3;
}

.project-image img {
    width: 100%;
    border-radius: 8px;
}