/* About.css */

#about {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0;
    max-width: 1200px;
}

#about h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
}

#about p {
    font-size: 16px;
    line-height: 1.5;
    color: #666;
    margin-bottom: 20px;
}

#about img {
    max-width: 100%;
    border-radius: 8px;
    margin-top: 20px;
}

/* About.css */

#about {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    margin: 20px 0;
}

#about .about-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#about .about-text {
    flex: 1;
    margin-right: 20px;
    /* Optional: Adds some spacing between the text and the image */
}

#about .about-image {
    flex: 1;
    max-width: 50%;
    /* Controls the width of the image container */
}

#about .about-image img {
    width: 100%;
    /* Makes the image responsive */
    border-radius: 8px;
    /* Optional: Rounds the corners of the image */
}