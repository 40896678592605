body {
  font-family: 'Helvetica', sans-serif;
  color: #ffffff;
  /* White text */
  background-color: #2c3e50;
  /* Dark background */
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  margin: auto;
  overflow: hidden;
}


header nav,
footer nav {
  display: flex;
  justify-content: center;
  gap: 20px;
}

section {
  background: #ffffff;
  margin: 20px 0;
  padding: 20px;
  border-radius: 8px;
}