/* Skills.css */

#skills {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0;
}

#skills h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
    justify-items: center;
    align-items: center;
}

.skill {
    background-color: #34495e;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
}

/* Optional: Add a hover effect */
.skill:hover {
    background-color: #3498db;
}