/* Footer.css */

footer {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0;
    max-width: 1200px;
    border-top: 1px solid #e0e0e0;
    text-align: center;
}

footer .social-links {
    margin-bottom: 10px;
}

footer .social-links a {
    margin: 0 15px;
    text-decoration: none;
    color: #333;
    transition: color 0.3s ease;
}

.social-links img {
    width: 50px;
    /* Adjust size as needed */
    height: auto;
    /* Maintain aspect ratio */
    margin-right: 10px;
    /* Space between icons */
}

footer .social-links a:hover {
    color: #007BFF;
}

footer p {
    font-size: 14px;
    color: #666;
}