/* Header.css */

header {
    background-color: #34495e;
    color: #ecf0f1;
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0;
    max-width: 1200px;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #2c3e50;

}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    color: #ecf0f1;
}

.title h1 {
    margin: 0;
    font-size: 36px;
}

.title h2 {
    margin: 0;
    font-size: 24px;
    color: #3498db;
    /* A lighter color for emphasis */
}

nav a {
    margin: 0 15px;
    color: #ecf0f1;
    text-decoration: none;
    transition: color 0.3s ease;
}

nav a:hover {
    color: #3498db;
}

.linkedin-link {
    margin-left: 20px;
    /* Adjust as needed */
}

.linkedin-link img {
    width: 70px;
    height: auto;
}